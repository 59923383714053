@import "colors";
@import "variables";
@import "mixins";

$COLUMN_HEADER_HEIGHT: 50px;

#documents-board {
  height: 100%;
  display: flex;

  .left-panel {
    max-width: 362px;
    width: 100%;
    height: 100%;
    min-width: 300px;
    overflow: auto;

    .url-field .ant-space-item:nth-child(1) {
      width: 100%;
    }

    .url-field .ant-space-item:nth-child(2) {
      height: 40px;
    }

    .mb-0 {
      margin-bottom: 0;
    }
  }

  .right-panel {
    overflow: auto;
    height: 100%;
    width: 100%;
    @include hide-scroll-bar;

    .search-and-filters {
      display: flex;
      flex-wrap: wrap;

      &-input {
        width: 272px;
        box-shadow: none;
        font-family: inter, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #667085;
        background-color: #f2f4f7;
        border: 1px solid #f2f4f7 !important;
        border-radius: 5px !important;
        padding-left: 10px;

        ::placeholder {
          color: #667085;
        }

        :focus {
          border: none;
        }

        input.ant-input {
          box-shadow: none;
          background-color: #f2f4f7 !important;
        }
      }

      &-searchoutlined {
        color: #98a2b3 !important;
        margin-right: 12px;
      }

      .filter {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        align-items: center;
        margin-left: 8px;
        margin-bottom: 10px;

        &-title {
          font-size: 14px;
          font-weight: 600;
          font-weight: 600;
          font-family: inter, sans-serif;
          line-height: 20px;
          line-height: 20px;
          letter-spacing: 2px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .documents-columns-holder {
    display: flex;
    overflow: auto;
    height: 100%;
    margin-top: 20px;
    gap: 16px;
  }

  .documents-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    // max-width: 600px;
    min-width: 300px;
    border-top: none;
    border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
  }

  .documents-column-header {
    display: flex;
    justify-content: space-between;
    justify-content: space-between;
    letter-spacing: 0px;
    margin-bottom: 10px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #101828;

    &-documentsCount {
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: right;
      color: #d0d5dd;
    }
  }

  .documents-column-body {
    background: linear-gradient(
      180deg,
      rgba(51, 51, 51, 0.05) 0%,
      rgba(51, 51, 51, 0) 100%
    );
    border-radius: 5px;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    overflow: auto;
  }

  .pr-0 {
    padding-right: 0;
  }

  .document-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    min-height: 120px;
    background-color: #ffffff;

    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: grab;

    &-title {
      font-size: 14px;
      color: #000000;
      font-size: 14px;
      color: black;
      width: 83%;
      font-weight: 500;
      font-family: inter, sans-serif !important;
      line-height: 16.94px;
      font-weight: 500;
      padding-bottom: 7px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: inter, sans-serif;
      line-height: 16.94px;
    }

    &-header {
      display: flex;
      justify-content: space-between;

      &-delete,
      &-info {
        cursor: default;
        font-size: 12px;

        & > span {
          cursor: default;

          &:hover {
            color: $HOVER_RED;
          }
        }
      }
    }

    &-actions {
      justify-content: space-around;
    }
  }

  .document-card-with-details {
    height: 270px;
    min-height: 270px;
  }

  .ellipsis-outlined-icon {
    color: #101828;
    font-size: 18px;
  }

  .filter-access-level {
    position: absolute;
    right: 7px;
    display: none;
  }

  .ant-modal-body {
    .modal-body {
      .documents-queue {
        overflow: auto;

        &-body {
          overflow: auto;

          .queued-document {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $APP_BLUE;
            width: 100%;
            height: 30px;
            border: 1px dashed $APP_BLUE;
            background: $APP_FOREGROUND;
            padding: 3px 10px;
            font-size: 18px;
            border-radius: 3px;
            margin-bottom: 10px;
            overflow: hidden;

            &-title {
              display: flex;
              align-items: center;
              max-width: 70%;

              &-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 10px;
                color: $APP_BLACK;
                margin-left: 3px;
                margin-right: 3px;
              }
            }
          }

          .failed-item {
            border: 1px dashed $BUTTON_RED;
          }
        }
      }
    }
  }

  .import-pane {
    height: 50%;
    max-height: 700px;
    overflow: auto;

    .upload-info {
      font-size: 12px;
      margin-top: 10px;
      display: inline-block;
      color: #00000073;
    }

    .upload-view {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .plus-circle-outlined {
        font-size: 86px !important;
        color: #1890ff;
      }

      &-wrapper {
        margin-left: 10px;

        span {
          font-size: 16px;
          text-align: left;
          color: #00000073;
        }
      }

      &-title {
        margin: 0;
        text-align: left;
        font-size: 24px;
      }
    }

    .document-entity-types-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .header-text {
        font-family: inter, sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #101828;
      }

      & > :nth-child(2) {
        justify-content: flex-end;
      }
    }
  }

  .queue-pane {
    overflow: auto;
  }

  .columns-pane {
    height: 100%;
    // width: 1080px;
    // width: 1080px;
  }
}

.ant-form-item {
  margin-bottom: 12px !important;
}

.ant-upload.ant-upload-drag {
  background-color: transparent !important;
  border: none !important;

  .ant-upload {
    padding: 0 !important;
  }
}

.ant-upload-list {
  max-height: 121px;
  overflow: auto;
}

.more-actions-rules-page {
  margin-left: 60px;
  cursor: pointer;
}

.menu-dropdown-head {
  border-radius: 8px !important;
  box-shadow: 0px 0px 4px 5px #00000008 !important;
  border: none !important;
  font-weight: 500;
  font-size: 14px;
  width: 176px;
  overflow: hidden !important;
}

.menu-item-dropdown {
  border: none;
  font-weight: 500;
  font-size: 14px;
  color: #344054 !important;
  margin-block: 0px !important;

  &:hover {
    color: #344054 !important;
    background: #f9fafb !important;
  }
}

.menu-item-dropdown-delete {
  font-weight: 600 !important;
  color: #f04438;
}

.menu-item-rules.hover:hover {
  background-color: #f5f5f5;
}

.entities-count {
  color: #98a2b3;
  font-family: inter, sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 12.1px;
  padding: 3px 4px 5px 0px;
}

.entities-count-values {
  color: #101828;
  font-family: inter, sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 12.1px;
}

.doc-card-detail {
  color: #98a2b3;
  font-family: inter, sans-serif;
  font-weight: 600;
  font-size: 10px;
  // line-height: 12.1px;
  padding: 3px 4px 5px 0px;
}

.doc-card-detail-value {
  color: #101828;
  font-family: inter, sans-serif;
  font-weight: 600;
  font-size: 10px;
  // line-height: 12.1px;
}

ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.menu {
  border-radius: 8px;
  box-shadow: 0px 0px 4px 5px #00000008;
  border: none;
  font-weight: 500;
  font-size: 14px;
  width: 176px;

  &:hover {
    border-radius: 8px !important;
    overflow: hidden;
  }
}

.upload-view-file-title {
  padding: 10px 16px 10px 16px;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  background-color: #ffffff;
  width: 55%;
  display: block;
  margin: 10px 55px;
  margin: 16px 55px 7px 55px;
}

.fileimport {
  margin-bottom: 0px !important;
}

.upload-view-wrapper {
  justify-content: center;
  display: grid;
  background-color: #f9fafb;
  border: 1px dashed #d0d5dd;
  border-radius: 8px;
  width: 352px;
  text-align: center;
}

.upload-view-title-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: 109px;
  color: #101828;

  .upload-icon {
    width: 48px;
    height: 48px;
    padding: 12px 0 0 0;
    gap: 0;
    border-radius: 28px 0 0 0;
    border-width: 8px 0 0 0;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-data {
    color: #98a2b3;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 18px;
  }
}

.upload-view-title-text-drag {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.upload-icon {
  width: 48px;
  height: 48px;
  padding: 12px 0 0 0;
  gap: 0;
  border-radius: 28px 0 0 0;
  border-width: 8px 0 0 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.import-modal-heading-icon {
  margin-bottom: 20px;
}

.import-modal-heading {
  margin-bottom: 8px !important;
}

.import-modal-heading-title {
  display: block;
  height: 28px;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #101828;
}

.form-imports {
  margin: 0px 0px 12px 2px !important;
}

.form-imports .ant-form-item-label {
  padding: 0 0 6px !important;
}

.tabsAll .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
  gap: 16px;
  height: 24px;
  width: 126px;
}

.tabsAll .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  margin: 0px !important;
}

.import-url-fields {
  margin: 2px !important;
  width: 98% !important;
  box-shadow: 0px 0px 0px 4px #efefef8f !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
}

.import-text-fields {
  margin: 2px 0px 20px 2px !important;
  width: 98% !important;
  height: 76px !important;
  box-shadow: 0px 0px 0px 4px #efefef8f !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
}

.form-imports .ant-col ant-form-item-control {
  width: 350px;
}

.import-button-head {
  position: relative;
  display: flex;
  margin-top: 40px;
}

.import-button-cancel {
  line-height: 24px !important;
  height: 44px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #344054 !important;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  padding: 10px 18px !important;
  border-radius: 8px !important;
  margin-right: 12px;
}

.import-button-import {
  line-height: 24px !important;
  height: 44px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #ffffff !important;
  background-color: #344054 !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  padding: 10px 18px !important;
  border-radius: 8px !important;

  &:hover {
    color: #ffffff !important;
  }
}

import-button-import > span:hover {
  color: #ffffff !important;
}

.ant-btn.import-button-import > span {
  color: #ffffff !important;
}

input.ant-input:hover {
  border-color: #d0d5dd !important;
}

input.ant-input:focus {
  border-color: #d0d5dd !important;
}

.checkbox-pill {
  display: inline-block;
  font-family: inter, sans-serif !important;
  cursor: pointer;
  padding: 4px 12px;
  color: #344054;
  background-color: #f2f4f7;
  border-radius: 16px;
  font-weight: 500;
  font-size: 14px;
  margin: 4px 8px 4px 0px;
  transition: background-color 0.3s;

  &.checked {
    background-color: #344054;
    color: #ffffff;
  }

  .hidden-checkbox {
    display: none;
  }
}

.ant-modal-content {
  border-radius: 12px !important;
}

.ant-tabs-nav::before {
  border-bottom: none !important;
}

.ant-tabs-ink-bar {
  display: none !important;
}

.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.slider-mainHead {
  max-width: 64px !important;
  min-width: 64px !important;
  width: 64px !important;
}

.slider-mainHead {
  background-color: black !important;

  &-logo {
    margin-top: 19px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &-MdArrowBackIos {
    cursor: pointer;
    color: white;
  }

  &-MdArrowForwardIos {
    cursor: pointer;
    color: white;
    margin-block: 0.4rem;
  }

  &-Divider {
    background-color: #98a2b3 !important;
    height: 1px;
    margin: 17px 0 !important;
    opacity: 20%;
  }

  &-menu {
    background-color: black !important;
    color: white !important;

    &-item {
      border-radius: 5px;
      width: auto !important;
      margin-inline: 8px !important;
    }
  }
}

.slider-mainHead-menu-item {
  padding: 10px 16px !important;
}

.header-search-input {
  box-shadow: none !important;
  background-color: #f2f4f7 !important;
  border-radius: 5px !important;
  border: none !important;
  color: #667085 !important;
  width: 274px;
  height: 40px;
  gap: 0px;
  text-align: left !important;
}

.header-search-input.ant-btn:hover,
.header-search-input.ant-btn:focus {
  color: #667085 !important;
}

.header-search-outlined {
  color: #98a2b3 !important;
  margin-right: 8px;
}

.avatarmargin {
  cursor: pointer;
}

.filter-selector .ant-select-selection-item {
  border-radius: 8px;
  color: #101828;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  outline: none !important;
  gap: 8px;
}

.ant-select-arrow {
  color: #101828 !important;
  top: 46% !important;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
  padding-left: 15px !important;
}

.filter-selector .ant-select-dropdown {
  height: 40px !important;
  border-radius: 8px !important;
}

.ant-select-selector {
  border: 1px solid #eaecf0 !important;
  border-radius: 8px !important;
  padding: 2px 8px !important;
}

.header-search-outlined {
  color: #98a2b3 !important;
}

.main-searchBox {
  top: 8px !important;
}

.main-searchBox .ant-modal-content {
  width: auto !important;
  border-radius: 8px !important;
}

.main-searchBox .ant-modal-content .ant-modal-body {
  padding: 12px !important;
}

.filter-selector .ant-select-selection-item {
  border-radius: 8px;
  color: #101828 !important;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  outline: none !important;
}

.main-searchBox ~ .ant-modal-mask {
  background-color: #101828;
  opacity: 80%;
}

.filter-selector .ant-select-selector {
  height: 40px !important;
  box-shadow: none !important;
}

.filter-divider {
  margin: 0px !important;
}

.filter-dropdown {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 40px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 10px 16px 10px 16px;
  gap: 8px;
  border: 1px solid #eaecf0;

  &-title {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }

  &-selected {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    width: auto;
    height: 20px;
    padding: 2px 8px 2px 8px;
    gap: 0px;
    border-radius: 16px;
    background: #eaecf0;
  }

  &-selectedDate {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    width: auto;
    height: 20px;
    padding: 2px 8px 2px 8px;
    gap: 0px;
    border-radius: 16px;
    background: #eaecf0;
  }
}

.filter-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 5px #00000008;
}

.filter-menu {
  padding: 0px !important;
  border-radius: 8px !important;
  height: 40px !important;

  :hover {
    border-radius: 8px !important;
  }
}

.filter-selector-options {
  padding: 0px !important;
  border-radius: 8px !important;
  color: #344054;
  width: 184px !important;
  max-height: 160px !important;
  overflow-y: auto !important;

  scrollbar-color: #eaecf0 #f0f0f0;
  scrollbar-width: thin;
}

.filter-selector-options::-webkit-scrollbar {
  width: 8px;
}

.filter-selector-options::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
  border-radius: 4px;
  border: 1px solid #eaecf0;
}

.filter-selector-options-item {
  height: 40px !important;
  padding: 0px !important;

  > .ant-select-item-option-content {
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #344054;
  }

  > .confidence-score-list-item-fl-span {
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #344054;
  }
}

.All {
  font-weight: 600 !important;
}

.filter-selector {
  :focus-visible {
    outline: none !important;
  }

  box-shadow: none !important;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.custom-range-picker {
  width: 186px;
  border-radius: 8px !important;
  height: 40px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  box-shadow: none !important;
  border-color: #eaecf0 !important;

  &:hover {
    border-color: #eaecf0 !important;
  }

  &:focus-visible {
    outline: none !important;
  }

  & > .ant-picker-input > input {
    color: #101828;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  & > .ant-picker-range-separator {
    padding: 0 !important;
  }
}

.custom-range-picker-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-selector-options .rc-virtual-list-holder {
  max-height: 160px !important;
  max-height: 160px !important;
  overflow-y: auto !important;
  scrollbar-gutter: stable;
  scrollbar-color: #eaecf0 #f0f0f0;
  scrollbar-width: thin;
}

.filter-selector-options .rc-virtual-list-holder::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
  border-radius: 4px;
  border: 1px solid #eaecf0;
}

.filter-selector-options .rc-virtual-list-holder::-webkit-scrollbar {
  width: 8px;
}

.rules {
  max-height: none !important;
  overflow: visible !important;
}

.rules .rc-virtual-list-holder {
  max-height: none !important;
  overflow: visible !important;
}
